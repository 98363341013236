import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import crypto from "crypto-browserify";
// import stream from "stream-browserify";
// import { Buffer } from 'buffer';
// import cipher from 'browserify-cipher';

export default function Home() {
    const [result, setResult] = useState({
        encrypt: "",
        decrypt: "",
    })
    const [value, setValue] = useState({
        value: "",
        key: ""
    })
    const [value2, setValue2] = useState({
        value: "",
        key: ""
    })
    let algorithm = process.env.REACT_APP_ENCRYPTION_ALGORITHM;
    let ivLength = 12;
    let tagLength = 16;
    let format = process.env.REACT_APP_ENCRYPTION_FORMAT;
    // let key = process.env.REACT_APP_ENCRYPTION_KEY;
    // console.log("key: ", key)
    function isJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    const encrypt = (data) => {
        try {
            const iv = crypto.randomBytes(ivLength);
            const cipher = crypto.createCipheriv(algorithm, value?.key, iv);
            let cipherText = cipher.update(JSON.stringify(data), "utf-8");
            cipherText = Buffer.concat([cipherText, cipher.final()]);
            const tag = cipher.getAuthTag();
            const encryptedData = Buffer.concat([iv, cipherText, tag]);
            setResult({ ...result, encrypt: encryptedData.toString(format) })
            return encryptedData.toString(format);
        } catch (e) {
            return false;
        }
    };
    const decrypt = (data) => {
        try {
            const encryptedBuffer = Buffer.from(data, format);
            const iv = encryptedBuffer.slice(0, ivLength);
            const cipherText = encryptedBuffer.slice(ivLength, -tagLength);
            const tag = encryptedBuffer.slice(-tagLength);
            const decipher = crypto.createDecipheriv(algorithm, value2?.key, iv);
            decipher.setAuthTag(tag);
            let data2 = decipher.update(cipherText, "base64", "utf8");
            if (data2 && data2 !== "") {
                data2 += decipher.final("utf8");
                console.log("data2: ", data2)
                setResult({ ...result, decrypt: isJsonString(data2) ? JSON.parse(data2) : data2 })
                return isJsonString(data2) ? JSON.parse(data2) : data2;
            } else {
                setResult()
                return "No result found"
            }
        } catch (e) {
            return false;
        }
    };
    console.log("result", result)

    const handleOnChange = (e) => {
        setValue({ ...value, [e.target.name]: e.target.value })
    }
    const handleOnChange2 = (e) => {
        setValue2({ ...value2, [e.target.name]: e.target.value })
    }
    const [errors, seterrors] = useState(false)

    const submitReq = (e) => {
        e.preventDefault()
        let data = {
            ...value
        }


        // if (data?.key == key) {
        //     encrypt(data)
        //     seterrors(false)
        // } else {
        //     seterrors(true)
        // }
        encrypt(data)
    }
    const submitReq2 = (e) => {
        e.preventDefault()
        // if (value2?.key == key) {
        //     decrypt(value2?.value)
        //     seterrors(false)
        // } else {
        //     seterrors(true)
        // }
        decrypt(value2?.value)
    }

    // console.log("value: ", value)
    // console.log("value2: ", value2)

    return (
        <div className="page_content pt-4">
            <div className="container">
                <div className="row">
                    <div className="col-sm-6">
                        <h4>Encrypt</h4>
                        <Form>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Enter JSON payload</Form.Label>
                                <Form.Control as="textarea" rows={4} name="value" onChange={handleOnChange} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Encryption key</Form.Label>
                                <Form.Control type="text" placeholder="" name="key" onChange={handleOnChange} />
                                {/* <Form.Control type="text" placeholder="" name="key" onChange={handleOnChange} isInvalid={errors && (value?.key == "" || value?.key !== key)} /> */}
                                {/* <Form.Control.Feedback type="invalid">Please enter valid key</Form.Control.Feedback> */}
                            </Form.Group>
                            <Button variant="primary" onClick={submitReq}>Submit</Button>
                            <hr />
                            <Form.Group className="pt-3" controlId="exampleForm.ControlTextarea2">
                                <Form.Label>Output</Form.Label>
                                <Form.Control as="textarea" rows={4} value={result ? result.encrypt : ""} />
                            </Form.Group>
                        </Form>
                    </div>
                    <div className="col-sm-6">
                        <h4>Decrypt</h4>
                        <Form>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Enter encrypted base64 payload</Form.Label>
                                <Form.Control as="textarea" rows={4} name="value" onChange={handleOnChange2} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Encryption key</Form.Label>
                                <Form.Control type="text" placeholder="" name="key" onChange={handleOnChange2} />
                                {/* <Form.Control type="text" placeholder="" name="key" onChange={handleOnChange2} isInvalid={errors && (value2?.key == "" || value2?.key !== key)} /> */}
                                {/* <Form.Control.Feedback type="invalid">Please enter valid key</Form.Control.Feedback> */}
                            </Form.Group>
                            <Button variant="primary" onClick={submitReq2}>Submit</Button>
                            <hr />
                            <Form.Group className="pt-3" controlId="exampleForm.ControlTextarea2">
                                <Form.Label>Output</Form.Label>
                                <Form.Control as="textarea" rows={4} value={result ? JSON.stringify(result.decrypt) : ""} />
                            </Form.Group>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    )
}